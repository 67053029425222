<template>
  <section class="dashboard">






    <div class="page-header">
      <h3 class="page-title">
      <span class="page-title-icon bg-gradient-primary text-white mr-2">
        <i class="mdi mdi-home"></i>
      </span> Panel de inicio</h3>

    </div>


    


<!--
    <div class="grid-margin stretch-card" v-for="(dev,index) in developments" :key="index">
    <div class="card">
      <div class="card-body">
        
          <h4 class="card-title float-left">{{ dev.name }}</h4>

          <div class="row clear-both">

              <div class="col-sm-3 col-lg-3 col-md-3">

                <img :src="thumb(dev.featured_image)"/>

              </div>
          </div>

       
        </div>
    </div>
  </div>-->


    <div class="row">
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-success card-img-holder text-white">
         


          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Ingresos por ventas <i class="mdi mdi-diamond mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">${{ $numberFormat(incoming_sale) }}</h2>
            <h6 class="card-text">Representa un {{ revenue_percent }}% del total</h6>
          </div>
        </div>
      </div>


      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-danger card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Otorgado en crédito <i class="mdi mdi-chart-line mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">${{ $numberFormat(sale_receivable) }}</h2>
            <h6 class="card-text">Representa un {{receivable_percent}}% del total</h6>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-info card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Valor total de las ventas <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">${{ $numberFormat(total_sale) }}</h2>
            <h6 class="card-text">{{ sold_properties }} unidades vendidas</h6>
          </div>
        </div>
      </div>

    </div>
  


    <div class="row">
      <div class="col-md-7 grid-margin stretch-card">
        <div class="card">


          <div class="card-body">
                <div class="clearfix">
                    <h4 class="card-title float-left">Mis desarrollos</h4>



                    <div class="list-developments" v-if="hasDevelopments">

                      <router-link :to="'/developments/view/'+item.id" class="item-development" v-for="(item, index) in developments" :key="index">

                          <img class="thumb-development" v-bind:src="thumb(item.featured_image)">

                        <div class="development-detail">
                          <h5>{{ item.name }}
                          </h5>
                          <small>
                            {{ item.use_type }}
                          </small>
                          
                          <p>{{ item.description }}</p>
                        </div>

                      </router-link>

                    </div>

                    <div class="no-developments" v-if="!hasDevelopments">
                      <br>
                      <br>
                      <p>No hay desarrollos</p>
                      <router-link :to="'/developments/create/'" class="action-link"><i class="mdi mdi-home-plus-outline"></i>Crea uno</router-link>

                    </div>


                    <!--
                    <div class="table-responsive">





                            <div class="pretable" v-if="loading">
                              <b-skeleton-table
                              :rows="3"
                              :columns="1"
                              
                              :table-props="{ bordered: false, striped: false }"
                            ></b-skeleton-table>
                            </div>
                            
                            <table class="table">
                              
                                  <tbody >
                                    
                                    <tr v-for="(item,index) in developments" :key="index">
                                      
                                      <td>{{ item.name }}</td>
                                      
                                      <td> 
                                        <img v-bind:src="thumb(item.featured_image)" value=""/>
                                      </td>
                                    </tr>
                                  
                                  
                                  
                                  </tbody>
                            </table>


              </div>

            -->

          </div>

        </div>


          <!--
          <div class="card-body">
            <div class="clearfix">
              <h4 class="card-title float-left">Visit And Sales Statistics</h4>
              <div id="visit-sale-chart-legend" class="rounded-legend legend-horizontal legend-top-right float-right">
                <ul>
                  <li>
                    <span class="legend-dots bg-gradient-primary"></span>CHN
                  </li> 
                  <li>
                      <span class="legend-dots bg-gradient-danger"></span>USA
                  </li> 
                  <li>
                        <span class="legend-dots bg-gradient-info"></span>UK
                  </li>
                </ul>
              </div>
            </div>
              <visitAndSalesStatitics class='mt-5' :height='170'></visitAndSalesStatitics>
          </div>
        -->


        </div>
      </div>
      <div class="col-md-5 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Resumen de ventas</h4>
            <trafficSourceChart :height='200'></trafficSourceChart>
            <div id="traffic-chart-legend" class="rounded-legend legend-vertical legend-bottom-left pt-4">
              <ul>
                <li>
                  <span class="legend-dots bg-gradient-info"></span>Disponible
                  <span class="float-right">30%</span>
                </li> 
                <li>
                  <span class="legend-dots bg-gradient-success"></span>Apartado
                  <span class="float-right">30%</span>
                </li> 
                <li>
                  <span class="legend-dots bg-gradient-danger"></span>Vendido
                  <span class="float-right">40%</span>
                  </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Recent Tickets</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> Assignee </th>
                    <th> Subject </th>
                    <th> Status </th>
                    <th> Last Update </th>
                    <th> Tracking ID </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face1.jpg" class="mr-2" alt="image"> David Grey </td>
                    <td> Fund is not recieved </td>
                    <td>
                      <label class="badge badge-gradient-success">DONE</label>
                    </td>
                    <td> Dec 5, 2017 </td>
                    <td> WD-12345 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face2.jpg" class="mr-2" alt="image"> Stella Johnson </td>
                    <td> High loading time </td>
                    <td>
                      <label class="badge badge-gradient-warning">PROGRESS</label>
                    </td>
                    <td> Dec 12, 2017 </td>
                    <td> WD-12346 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face3.jpg" class="mr-2" alt="image"> Marina Michel </td>
                    <td> Website down for one week </td>
                    <td>
                      <label class="badge badge-gradient-info">ON HOLD</label>
                    </td>
                    <td> Dec 16, 2017 </td>
                    <td> WD-12347 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face4.jpg" class="mr-2" alt="image"> John Doe </td>
                    <td> Loosing control on server </td>
                    <td>
                      <label class="badge badge-gradient-danger">REJECTED</label>
                    </td>
                    <td> Dec 3, 2017 </td>
                    <td> WD-12348 </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  -->




    <!--
    <div class="row">
      <div class="col-lg-5 grid-margin stretch-card">
        <div class="card">
          <date-picker v-model= "time1" valueType= "format" inline></date-picker>
        </div>
      </div>
      <div class="col-lg-7 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Recent Updates</h4>
            <div class="d-flex">
              <div class="d-flex align-items-center mr-4 text-muted font-weight-light">
                <i class="mdi mdi-account-outline icon-sm mr-2"></i>
                <span>jack Menqu</span>
              </div>
              <div class="d-flex align-items-center text-muted font-weight-light">
                <i class="mdi mdi-clock icon-sm mr-2"></i>
                <span>October 3rd, 2018</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 pr-1">
                <img src="../../assets/images/dashboard/img_1.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
                <img src="../../assets/images/dashboard/img_4.jpg" class="mw-100 w-100 rounded" alt="image">
              </div>
              <div class="col-6 pl-1">
                <img src="../../assets/images/dashboard/img_2.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
                <img src="../../assets/images/dashboard/img_3.jpg" class="mw-100 w-100 rounded" alt="image">
              </div>
            </div>
            <div class="d-flex mt-5 align-items-top">
              <img src="../../assets/images/faces/face3.jpg" class="img-sm rounded-circle mr-3" alt="image">
              <div class="mb-0 flex-grow">
                <h5 class="mr-2 mb-2">School Website - Authentication Module.</h5>
                <p class="mb-0 font-weight-light">It is a long established fact that a reader will be distracted by the readable content of a page.</p>
              </div>
              <div class="ml-auto">
                <i class="mdi mdi-heart-outline text-muted"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 stretch-card grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Project Status</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> # </th>
                    <th> Name </th>
                    <th> Due Date </th>
                    <th> Progress </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 1 </td>
                    <td> Herman Beck </td>
                    <td> May 15, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 2 </td>
                    <td> Messsy Adam </td>
                    <td> Jul 01, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-danger" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 3 </td>
                    <td> John Richards </td>
                    <td> Apr 12, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-warning" role="progressbar" style="width: 90%" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 4 </td>
                    <td> Peter Meggik </td>
                    <td> May 15, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-primary" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 5 </td>
                    <td> Edward </td>
                    <td> May 03, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-danger" role="progressbar" style="width: 35%" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 5 </td>
                    <td> Ronald </td>
                    <td> Jun 05, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-info" role="progressbar" style="width: 65%" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 stretch-card grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title text-white">Todo</h4>
            <todo-list />
          </div>
        </div>
      </div>
    </div>

    -->


  </section>
</template>

<script>
//import visitAndSalesStatitics from '../../components/charts/widgets/visitAndSalesStatitics'
import trafficSourceChart from '../../components/charts/widgets/trafficSourceChart'
//import todoList from '../../components/apps/todoList'
//import DatePicker from 'vue2-datepicker';
import api from '@/util/api.js';


export default {
  name: 'dashboard',
  components: {
  //  DatePicker,
    trafficSourceChart,
   // visitAndSalesStatitics,
  //  todoList


  },
  data() {
    return {
      loading:false,
      developments:[],
     time1:'',
     sold_properties:0,
     sale_receivable:0,
     incoming_sale:0,
     revenue_percent:0,
     receivable_percent:0,
     total_sale:0
    };
  },

  computed:{

    hasDevelopments(){

        return this.developments.length>0?true:false;


    }

  },
  methods: {
    getDashboard() {

      
      this.loading=true;
      api.get("/dashboard/index").then(response => {
        const data = response.data;

          this.developments=data.devs;
          this.incoming_sale=data.incoming_sale;
          this.sale_receivable=data.sale_receivable;
          this.sold_properties=data.sold_properties;
          this.total_sale=data.total_sale;
          
          


        //this.$router.push('/');
      }).catch(error => {
        console.log(error);
      }).finally(()=>{

          this.loading=false;


      });
    },
  },

  beforeMount() {

   // localStorage.setItem('access_token','');
    if(localStorage.getItem('access_token')){

      this.getDashboard();

    }else{
      this.$router.push("/user/login")
    }
},

}



</script>




<style scoped>

.clear-both{clear:both}

</style>